import validate from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/atlassian/pipelines/agent/build/ui/middleware/auth.global.ts";
import cart_45global from "/opt/atlassian/pipelines/agent/build/ui/middleware/cart.global.ts";
import checkout_45global from "/opt/atlassian/pipelines/agent/build/ui/middleware/checkout.global.ts";
export const globalMiddleware = [
  validate,
  auth_45global,
  cart_45global,
  checkout_45global
]
export const namedMiddleware = {}